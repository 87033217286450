<div class="bgContainer">
<!--	<app-page-loader></app-page-loader>-->
	<div class="mainForm">
		<div class="mainForm__left">
			<a *ngIf="!isPhone()" class="mainForm__back" href="http://www.sslg.com">← Back to SSLG.com</a>
			<h1 class="mainForm__title">Modern Technology<br/> To Help <u>You</u></h1>
			<blockquote class="mainForm__quote">
				<p>Status Star is the only web platform that allows you to:</p>
				<ul>
					<li>Check the status of your SSDI claim </li>
					<li>Easily add a recap of a medical visit using just your voice</li>
					<li>Receive reminders for appointments</li>
					<li>Upload critical documents for your claim using your phone’s camera </li>
					<li>And much, much more…</li>
				</ul>
			</blockquote>
		</div>

		<div class="mainForm__right">
			<a *ngIf="!isPhone()" class="mainForm__back mainForm__back_mobile" href="http://www.sslg.com">← Back to SSLG.com</a>
			<form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="showLoginForm" id="login">
				<img class="login-form__logo" src="../../../assets/img/logo_all_blue.svg">
				<label class="login-form__label" for="user">User ID</label>
				<mat-form-field>
					<input class="login-form__input" id="user" (keydown.enter)="sendUserName()" formControlName="username" matInput [type]="'text'" class="form-input" autocomplete="off">
				</mat-form-field>

				<button *ngIf="!showLoginStepTwo"
						class="form-send"
						type="button"
						(click)="sendUserName()"
						[disabled]="loginForm.controls.username.invalid || loading ">Next</button>

				<ng-template [ngIf]="showPasswordInput">
					<label class="login-form__label" for="password">Password</label>
					<mat-form-field>
						<input class="login-form__input" id="password" formControlName="password" matInput [type]="'password'" class="form-input">
					</mat-form-field>
					<div class="login-form__footer">
						<mat-checkbox formControlName="rememberMe" class="check-text">
							Remember Me
						</mat-checkbox>
						<!--					<input type="checkbox" id="resetPW">-->
						<!--					<label style="display:inline;font-weight:300;" for="resetPW">Remember Me</label>-->
						<span class="login-form__forgot">
						<a href="javascript:void(0)" (click)="showForgotPassword()" routerLink="/forgot-password">Forgot Password</a>
					</span>
					</div>
				</ng-template>

				<ng-template [ngIf]="showPinInput">
					<label class="login-form__label" for="password">Pin</label>
					<div class="login-form__digits" formArrayName="digits">
						<input (keydown.enter)="onSubmit()"
							   *ngFor="let field of loginForm.get('digits')['controls']; let i = index"
							   #inputs
							   [maxLength]="1"
							   [formControlName]="i"
							   (keydown)="check(i, field, $event)"
							   class="login-form__digit" type="tel">
					</div>
				</ng-template>

				<div class="mainForm__btns-wrapper" *ngIf="showLoginStepTwo">
					<button *ngIf="showLoginStepTwo"
							type="button"
							(click)="goToFirstLoginStep()"
							class="form-send">Back</button>
					<button *ngIf="showLoginStepTwo" class="form-send" [disabled]="(!canLoginWithPassword() && !canLoginWithPin()) || loading ">Login</button>
					<!--				<button>Login</button>-->
				</div>

			</form>

			<form class="login-form" [formGroup]="forgotPasswordForm" (ngSubmit)="onForgotPasswordSubmit()" *ngIf="showForgotPasswordForm" id="forgot_password">
				<img class="login-form__logo" src="../../../assets/img/logo_all_blue.svg">
				<label for="user">User ID</label>

				<mat-form-field>
					<input formControlName="username" matInput [type]="'text'" class="form-input" autocomplete="off">
				</mat-form-field>

				<div class="form-btn-submit">
					<button
						class="form-send forgot-pass-btn"
						(click)="showLogin()">
						Back
					</button>

					<button
						class="form-send forgot-pass-btn"
						[disabled]="!forgotPasswordForm.valid || loading ">
						Send
					</button>
				</div>
			</form>

		</div>

	</div>


	<svg class="curve" xmlns="http://www.w3.org/2000/svg" height="92" viewBox="0 0 1900 92">
		<path d="M0,0S232.122,86.42,948,85c503.19-1,952-85,952-85V92.013H0V0Z"></path>
	</svg>

	<div class="footer footerContainer">
		<img class="footer__logo" src="../../../assets/img/logo-1.png">

		<div class="footer__info infoSection">
			<!-- <div>
				<ul class="footer__locations" id="locations">
					<li>Boston</li>
					<li>Dallas</li>
					<li>Denver</li>
					<li>Atlanta</li>
					<li>St. Louis</li>
				</ul>
			</div> -->

			<div class="footer__code">800-909-SSLG (7754)</div>
		</div>
	</div>

</div>
